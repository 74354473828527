import React, { FunctionComponent } from 'react'
import { graphql, PageProps } from 'gatsby'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { css } from 'styled-components'

import Page from '~/components/Page'
import MarkdownHTML from '~/components/MarkdownHTML'
import { FaqList } from '~/types/graphql'
import IndexLayout, { IndexLayoutDataProps } from '../layouts/IndexLayout'

// locale is provided through PageProps
// @see: https://www.gatsbyjs.org/docs/gatsby-internals-terminology/#pagecontext
export const query = graphql`
	query($locale: String) {
		...ContentfulMetaData
		...FooterData
		contentfulFaqList(contentfulid: { eq: "faq" }, node_locale: { eq: $locale }) {
			title
			faqItems {
				title
				description {
					childMarkdownRemark {
						html
					}
				}
			}
		}
	}
`

type Props = {
	data: {
		contentfulFaqList: FaqList
	}
} & IndexLayoutDataProps &
	PageProps

const FaqPage: FunctionComponent<Props> = ({ location, data, pageContext }) => {
	const { contentfulFaqList } = data
	return (
		<IndexLayout data={data} location={location} pageContext={pageContext}>
			<Page>
				<Container
					maxWidth="md"
					css={css`
						padding-bottom: 30px;
					`}
				>
					<Box mt={6} mb={6}>
						<Typography variant="h1" gutterBottom align="center">
							{contentfulFaqList.title}
						</Typography>
					</Box>
					{contentfulFaqList.faqItems.map((faqItem) => (
						<Box key={faqItem.title} my={6}>
							<Typography variant="h2" color="primary" gutterBottom align="center">
								{faqItem.title}
							</Typography>

							<MarkdownHTML textAlign="center" html={faqItem.description.childMarkdownRemark.html} />
						</Box>
					))}
				</Container>
			</Page>
		</IndexLayout>
	)
}

export default FaqPage
